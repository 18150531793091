import {createI18n} from "vue-i18n";
import pt from "./pt.json";

const messages = {
    pt,
}
const i18n = new createI18n({
    legacy: false,
    locale: process.env.VUE_APP_I18N_LOCALE || 'pt',
    globalInjection: true,
    fallbackLocale: process.env.VUE_APP_I18N_LOCALE || 'pt',
    messages
})

export default i18n;