import Vuex from './vuex';
import App from './App.vue';
import {createApp} from 'vue';
import pt from "./locales/pt.json";
import router from './router/router';
import {createI18n} from 'vue-i18n';
import VueTheMask from 'vue-the-mask';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
const messages = {
    pt,
}
const i18n = new createI18n({
    legacy: false,
    locale: 'pt',
    fallbackLocale: 'pt',
    messages
})
const app = createApp(App)
app.use(i18n);
app.config.globalProperties.$widthMd = (size = 1025, smaller = '<') => {
    if (smaller == '<') {
        return window.innerWidth < size;
    } else {
        return window.innerWidth > size;
    }
};
app.use(Vuex);
app.use(router);
app.use(VueTheMask);
app.use(VueSweetalert2);
app.use(VueAxios, axios);
app.use(Toast, {position: 'top-right'});
app.mount('#app');
