<template>
  <div id="app">
    <div class="auth-wrapper">
      <div class="auth-inner">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}

import(/* webpackChunkName: "moment" */'moment/moment');
import(/* webpackChunkName: "Theme" */ './assets/js/theme.js');
import(/* webpackChunkName: "jquery" */'jquery/dist/jquery.min');
import(/* webpackChunkName: "daterangeicker" */'daterangepicker');
import(/* webpackChunkName: "BootStrapBundle" */'bootstrap/dist/js/bootstrap.bundle.min');
</script>

<style lang="css" scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "assets/fonts/style.css";
@import "assets/css/main.css";
@import '~@fortawesome/fontawesome-free/css/all.css';
@import "assets/css/prism/prism.css";
</style>

<style lang="scss">

.required:after {
  content:" *";
  color: red;
}
</style>
