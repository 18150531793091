import i18n from "../locales/i18n"
import {useToast} from "vue-toastification";
export default {
    success(message) {
        const toast = useToast();
        toast.success(i18n.global.t(message));
    },
    info(message){
        const toast = useToast();
        toast.info(i18n.global.t(message));
    },
    error(message, useT= false) {
        const toast = useToast();
        if (useT) {
            toast.error(message);
            return;
        }
        toast.error(i18n.global.t(message));
    }
}