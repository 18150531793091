import {http} from '@/services/config';

export default {
    getCountPendingSign({commit}, {sistems = 'all'}) {
        let filters = {};
        filters.sistems = sistems;
        http.get('/v1/dashboard/pending-sign', {params:{filters}}).then(resp => {
            commit('setCountPendingSign', resp.data['document-pending']?.total);
        }).catch(() => {
            commit('setCountPendingSign', 0);
        });
    },
    getCountStatusAssign({commit},{sistems = 'all'}) {
        let filters = {};
        filters.sistems = sistems;
        http.get('/v1/documentos/dados?days=7', {params:{filters}}).then(resp => {
            commit('setCountStatusAssign', resp.data);
        }).catch(() => {
            commit('setCountStatusAssign', {});
        });
    },
    getCountStatusAssignByDate({commit}, {days = 7, sistems = 'all'}) {
        commit('changeLoading', true);
        let filters = {};
        filters.sistems = sistems;
        http.get(`/v1/documentos/dados?days=${days}`, {params:{filters}}).then(resp => {
            commit('setCountStatusAssignByDate', resp.data);
            commit('changeLoading', false);
        }).catch(() => {
            commit('setCountStatusAssignByDate', {});
            commit('changeLoading', false);
        })
    },
    getGraficDashboard({commit, state},{isDay, sistems = 'all'}) {
        state.loadChart = true;
        let filters = {};
        filters.sistems = sistems;
        if (isDay){
            http.get('/v1/documentos/dashboardGrafic?days=30', {params:{filters}}).then(resp => {
                commit('setGraficDashboard', resp.data);
                state.loadChart = false;
            }).catch(() => {
                commit('setGraficDashboard', {});
                state.loadChart = false;
            });
        }else {
            http.get('/v1/documentos/dashboardGrafic', {params:{filters}}).then(resp => {
                commit('setGraficDashboard', resp.data);
                state.loadChart = false;
            }).catch(() => {
                commit('setGraficDashboard', {});
                state.loadChart = false;
            });
        }
    },
}