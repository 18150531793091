export default {
    setCountPendingSign(state, value){
        state.resumPendignAdm = value ? value : 0;
    },
    setCountStatusAssign(state, value){
        state.resumStatusAssign = value;
    },
    setCountStatusAssignByDate(state, value){
        state.resumStatusAssign = value;
    },
    setGraficDashboard(state, value){
        state.dataGrafic = value
    },
    setFilterHome(state,value){
        state.filtersHome = value;
    }
}